import { Address, AddressContact } from "./Address";
import { CheckoutSession } from "./CheckoutSession";
import { AddressSource } from "./FulfillmentQuote";
import { AetherItemType, OrderItem, PaymentRestriction } from "./OrderItem";
import { PaymentMethodType } from "./PaymentMethodType";

export enum PaymentResult {
  SUCCESS = 'success',
  REVERTED = 'reverted',
  NOT_ATTEMPTED = 'notAttempted',
  FAILURE = 'failure',
  ERROR = 'error',
}

export type AetherPaymentRequest = OrderPayment & {
  addressSource: AddressSource
  savedAddressId?: string
  otpEnabled: boolean
}

export type AetherPaymentResponse = AetherPaymentRequest & {
  result: PaymentResult
  message?: string
}

export interface SubmitPaymentsRequest {
  sessionId: string
  userId: string
  paymentRequests: AetherPaymentRequest[]
  otpReq?: {
    id: string,
    otp: string
  }
}

export interface SubmitPaymentsResponse {
  session: CheckoutSession
  paymentResponses: AetherPaymentResponse[]
}

export interface OrderPayment {
  amount: number
  methodType: PaymentMethodType
  contact: AddressContact
  address: Address
  addressSource: AddressSource
  savedAddressId?: string
  poNumber?: string
  itemIds: string[]
  convenienceFee?: number
  convenienceFeeTax?: number,
  giftCardCode?: string,
  budgetId?: string,
  maximumAmount?: number,
  transactionId?: string,
  points?: number,
  validationResponse?: ValidateRequestResponse
}

export interface ItemPaymentDetails {
  restriction?: PaymentRestriction
  itemType: AetherItemType
  itemId: string
  applied: number
  total: number
}

export interface ValidateRequestResponse {
  amount?: number
}