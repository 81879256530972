import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { _useToasts } from "../../common/_useToasts";
import { getDiscountPrice, getFirstPrice, getShowProductPrice } from "../../helpers/product";
import ProductImageGallery from "../../components/product/ProductImageGallery";
import ProductDescriptionInfo from "../../components/product/ProductDescriptionInfo";
import ProductImageGallerySideThumb from "../../components/product/ProductImageGallerySideThumb";
import ProductImageFixed from "../../components/product/ProductImageFixed";
import { AuthContext } from "../../common/Auth";
import { useThemeSettingsData } from "../../layouts/state/useThemeSettingsData";
import { useThemeSettings } from "../../layouts/state/useThemeSettings";
import { useProductPriceState } from "../../components/product/state/useProductPriceState";

const ProductImageDescription = ({
  spaceTopClass,
  spaceBottomClass,
  galleryType,
  product,
  currency,
  cartItems,
  wishlistItems,
  artworkList,
  compareItems,
  artworkListById,
  kitConfig,
  hideImage,
  isModal,
}) => {
  artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
  const [selectedColor, setSelectedColor] = useState(null)
  const [selectedThumbImgColor, setSelectedThumbImgColor] = useState(null)
  const [selectedDecorations, setSelectedDecorations] = useState(null)
  const [singleSelectedDecorations, setSingleSelectedDecorations] = useState(null);
  const [selectedImage, setSelectedImage] = useState('');
  const [toggle, setToggle] = useState(false)
  const [isReplace, setIsReplace]=useState(true)
  const [hideImageState, setHideImage] = useState(false)

  useEffect(() => {
    setHideImage(hideImage);
  }, [hideImage]);

  const wishlistItem = wishlistItems?.filter(
    (wishlistItem) => wishlistItem.id === product.id,
  )[0]
  const compareItem = compareItems?.filter(
    (compareItem) => compareItem.id === product.id,
  )[0]
  const { _addToast } = _useToasts()
  const { userGroupDiscount, themeSettingsData, user, settingData, } = useContext(AuthContext)
  const [decimalsToShow, setDecimalsToShow] = useState(2);
  const {
		productSettings,
	} = useThemeSettingsData({ themeSettingsData });
  const {
    itempriceDisplay,
    priceDisplay,
  } = useThemeSettings({ settingData })

  const {
    showSellPrice,
    showSell,
    showPrice,
    offerPrice,
    discountType,
    finalProductPrice,
    finalDiscountedPrice,
    finalProductDiscPrice,
    discountedPrice,
} = useProductPriceState({product, currency, userGroupDiscount, itempriceDisplay, priceDisplay, user, kitConfig})
  useEffect(() => {
		if(!!productSettings?.decimalsToShow) {
			setDecimalsToShow(Number(productSettings?.decimalsToShow));
		}
	}, [productSettings])

  const [tempValue, setTempValue] = useState(0)
  const tempfun = () => {
    setTempValue(tempValue + 1)
  }

  return (
    <div
      className={`shop-area ${spaceTopClass ? spaceTopClass : ''} ${
        spaceBottomClass ? spaceBottomClass : ''
      }`}
    >
      <div className="pl-2 pr-2">
        <div className="row">
          <div className="col-lg-5 col-md-6">
            {/* product image gallery */}
            {hideImageState ? (<></>
            ) : galleryType === 'leftThumb' ? (
              <ProductImageGallerySideThumb
                product={product}
                thumbPosition="left"
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                artworkListById={artworkListById}
                selectedDecorations={selectedDecorations}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedThumbImgColor={setSelectedThumbImgColor}
                selectedThumbImgColor={selectedThumbImgColor}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
              />
            ) : galleryType === 'rightThumb' ? (
              <ProductImageGallerySideThumb
                product={product}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                artworkListById={artworkListById}
                selectedDecorations={selectedDecorations}
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedThumbImgColor={setSelectedThumbImgColor}
                selectedThumbImgColor={selectedThumbImgColor}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
              />
            ) : galleryType === 'fixedImage' ? (
              <ProductImageFixed
                product={product}
                selectedColor={selectedColor}
                artworkList={artworkList}
                artworkListById={artworkListById}
                selectedDecorations={selectedDecorations}
                selectedImage={selectedImage}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedThumbImgColor={setSelectedThumbImgColor}
                selectedThumbImgColor={selectedThumbImgColor}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
              />
            ) : (
              <ProductImageGallery
                product={product}
                selectedColor={selectedColor}
                setSelectedColor={setSelectedColor}
                artworkList={artworkList}
                selectedDecorations={selectedDecorations}
                setSelectedImage={setSelectedImage}
                showSell={showSell}
                showSellPrice={showSellPrice}
                offerPrice={offerPrice}
                discountType={discountType}
                finalProductDiscPrice={finalProductDiscPrice}
                selectedImage={selectedImage}
                artworkListById={artworkListById}
                singleSelectedDecorations={singleSelectedDecorations}
                setSelectedThumbImgColor={setSelectedThumbImgColor}
                selectedThumbImgColor={selectedThumbImgColor}
                isReplace={isReplace}
                setIsReplace={setIsReplace}
              />
            )}
          </div>
          <div className="col-lg-7 col-md-6">
            {/* product description info */}
            <ProductDescriptionInfo
              product={product}
              setSelectedColor={setSelectedColor}
              setSelectedThumbImgColor={setSelectedThumbImgColor}
              selectedThumbImgColor={selectedThumbImgColor}
              selectedColor={selectedColor}
              setToggle={setToggle}
              toggle={toggle}
              setSelectedDecorations={setSelectedDecorations}
              currency={currency}
              finalDiscountedPrice={finalDiscountedPrice}
              finalProductPrice={finalProductPrice}
              cartItems={cartItems}
              wishlistItem={wishlistItem}
              compareItem={compareItem}
              _addToast={_addToast}
              showSell={showSell}
              showSellPrice={showSellPrice}
              offerPrice={offerPrice}
              discountType={discountType}
              finalProductDiscPrice={finalProductDiscPrice}
              tempfun={tempfun}
              setSingleSelectedDecorations={setSingleSelectedDecorations}
              singleSelectedDecorations={singleSelectedDecorations}
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
              isReplace={isReplace}
              setIsReplace={setIsReplace}
              kitConfig={kitConfig}
              isModal={isModal}
              setHideImage={setHideImage}
              showPrice={showPrice}
              discountedPrice={discountedPrice} 
            />
          </div>
        </div>
      </div>
    </div>
  )
};

ProductImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array
};

const mapStateToProps = state => {
  return {
    currency: state.currencyData,
    cartItems: state.cartData.cartItems,
    wishlistItems: state.wishlistData,
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    compareItems: state.compareData
  };
};

export default connect(mapStateToProps)(ProductImageDescription);
