import React from 'react'
import { Box, Paper, Stack, styled, Tooltip, Typography } from '@mui/material'
import { OrderItem } from '../../models/OrderItem'
import { getItemColorText, getItemSizesText } from '../../helpers/checkout'
import {
  selectDebug,
} from '../../redux/selectors/checkoutSelectors'
import { ColorLens, Style } from '@material-ui/icons'
import { useAppSelector } from '../../redux/hooks'
import Decimal from 'decimal.js'
import useCurrencyDisplay from './useCurrencyDisplay'
import { Print } from '@mui/icons-material'

const ItemImage = styled('img')({
  maxWidth: '100%',
})

export function ItemListItem({
  item,
  big = false,
}: {
  item: OrderItem
  big?: boolean
}) {
  const { format } = useCurrencyDisplay()
  const debug = useAppSelector(selectDebug)

  return (
    <Stack
      direction={'row'}
      p={2}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={2}
    >
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <Box position={'relative'}>
          <Box width={'3rem'}>
            {(item.rows[0]?.image ?? item.quoteCustomImage[0]) && (
              <ItemImage
                src={item.rows[0]?.image ?? item.quoteCustomImage[0]}
                alt={item.product.name}
              />
            )}
          </Box>
          <Stack
            direction={'row'}
            position={'absolute'}
            bottom={'-12px'}
            right={0}
            component={Paper}
            bgcolor={'primary.main'}
            color={'white'}
            pl={'4px'}
            pr={'4px'}
          >
            {item.rows[0]?.personalization?.length > 0 && (
              <Tooltip title={'Personalization Added to Item'}>
                <Box
                  fontSize={'1rem'}
                  height={'24px'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Style fontSize="inherit" />
                </Box>
              </Tooltip>
            )}
            {!item.aetherData.isPrintProduct &&
            (item.rows[0]?.decoration?.length ?? 0) > 0 ? (
              <Tooltip title={'Artwork Added to Item'}>
                <Box
                  fontSize={'1rem'}
                  height={'24px'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <ColorLens fontSize="inherit" />
                </Box>
              </Tooltip>
            ) : undefined}
            {item.aetherData.isPrintProduct &&
            (item.rows[0]?.decoration?.length ?? 0) > 0 ? (
              <Tooltip title={'Print Product'}>
                <Box
                  fontSize={'1rem'}
                  height={'24px'}
                  display={'flex'}
                  alignItems={'center'}
                >
                  <Print fontSize="inherit" />
                </Box>
              </Tooltip>
            ) : undefined}
          </Stack>
        </Box>
        <Stack>
          <Typography variant={big ? 'body1' : 'body2'} gutterBottom>
            {item.product.name}
          </Typography>
          {item.aetherData.showColor && (
            <Typography variant={big ? 'body2' : 'lineCaption'}>
              Color: {getItemColorText(item)}
            </Typography>
          )}
          {item.aetherData.showSize && (
            <Typography variant={big ? 'body2' : 'lineCaption'}>
              Size: {getItemSizesText(item)}
            </Typography>
          )}
          <Typography variant={big ? 'body2' : 'lineCaption'}>
            Qty: {item.quantity}
          </Typography>
          {debug && (
            <Typography variant={big ? 'body2' : 'lineCaption'}>
              Discount: {format(item.aetherData.discount)}{' '}
              {item.aetherData.displayTotalPrice === 0
                ? 0
                : new Decimal(item.aetherData.discount)
                    .times(100)
                    .div(item.aetherData.displayTotalPrice)
                    .toFixed(2)}
              %
            </Typography>
          )}
          {debug && (
            <Typography variant={big ? 'body2' : 'lineCaption'}>
              Tax: {format(item.aetherData.displayTax)}{' '}
              {item.aetherData.displayTotalPrice === 0
                ? 0
                : new Decimal(item.aetherData.displayTax)
                    .times(100)
                    .div(item.aetherData.displayTotalPrice)
                    .toFixed(2)}
              %
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack>
        {item.aetherPriceDisabled ? (
          <Typography variant={big ? 'body1' : 'body2'}> - </Typography>
        ) : (
          <Typography variant={big ? 'body1' : 'body2'}>
            {format(
              item.aetherData.displayTotalPrice,
              item.aetherData.decimalsToShow,
            )}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
