import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { useGetFulfillmentTypesQuery } from '../../../redux/api/fulfillmentMethodApi'
import { Alert, Skeleton, Stack } from '@mui/material'
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod'
import FulfillmentTypeCard from './FulfillmentTypeCard'
import { LocalShipping, Place } from '@mui/icons-material'
import ShippingMethodContent from './ShippingMethodContent'
import PickupMethodContact from './PickupMethodContent'
import { FieldValues, FormContainer, useForm } from 'react-hook-form-mui'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  selectContactInfo,
  selectFulfillments,
  selectParams,
  selectSessionId,
  selectSettings,
} from '../../../redux/selectors/checkoutSelectors'
import { useUpdateFulfillmentDetailsMutation } from '../../../redux/api/checkoutApi'
import { Fulfillment } from '../../../models/Order'
import { completeEditStep } from '../../../redux/reducers/checkoutReducer'
import { getError, getValidFulfillments } from '../../../helpers/checkout'
import OptionCard from '../OptionCard'
import WholePageSpinner from '../../../common/WholePageSpinner'

export interface FulfillmentForm extends FieldValues {
  fulfillments: Fulfillment[]
}

export default function FulfillmentActiveContent() {
  const dispatch = useAppDispatch()
  const params = useAppSelector(selectParams)
  const settings = useAppSelector(selectSettings)
  const sessionId = useAppSelector((state) => selectSessionId(state, params))
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const contactInfo = useAppSelector((state) =>
    selectContactInfo(state, params),
  )
  const fulfillments = useAppSelector((state) =>
    selectFulfillments(state, params),
  )
  const { data: types, isLoading: typesLoading } = useGetFulfillmentTypesQuery()

  const [
    updateFulfillmentDetails,
    { isLoading: loadingUpdate, error: updateError, isSuccess: updateSuccess },
  ] = useUpdateFulfillmentDetailsMutation()

  const formContext = useForm<FulfillmentForm>({
    defaultValues: useMemo(() => {
      const validFulfillments = getValidFulfillments(settings, fulfillments)
      if (validFulfillments.length > 0) {
        return { fulfillments: validFulfillments }
      } else {
        return { fulfillments: [{ contact: contactInfo }] }
      }
    }, [fulfillments, contactInfo, settings]),
  })

  const { reset } = formContext

  const handleSubmit = async (data: FulfillmentForm) => {
    try {
      if (!sessionId || !params) {
        return
      }
      const fulfillments = data.fulfillments.map((f) => ({
        methodId: f.method.methodId,
        quoteId: f.quoteId,
        contact: f.contact,
      }))
      await updateFulfillmentDetails({
        sessionId,
        userId: params.userId,
        fulfillmentRequests: fulfillments,
      })
    } catch (error) {}
  }

  useEffect(() => {
    if (types?.length === 1) {
      setSelectedType(types[0])
    }
  }, [types])

  useEffect(() => {
    const validFulfillments = getValidFulfillments(settings, fulfillments)
    if (validFulfillments?.[0]) {
      setSelectedType(fulfillments[0].method.type)
    }
    if (validFulfillments.length > 0) {
      reset({ fulfillments: validFulfillments })
    } else {
      reset({ fulfillments: [{ contact: contactInfo }] })
    }
  }, [fulfillments, contactInfo, settings])

  useEffect(() => {
    if (updateSuccess) {
      dispatch(completeEditStep())
    }
  }, [updateSuccess])

  const handleClick = (type: FulfillmentMethodType) => () => {
    setSelectedType(type)
    reset((pv) => ({
      fulfillments: [
        {
          contact: { ...contactInfo, ...(pv.fulfillments?.[0]?.contact ?? {}) },
        },
      ],
    }))
  }

  return (
    <FormContainer formContext={formContext}>
      {loadingUpdate && <WholePageSpinner text='Updating Delivery Details' />}
      <Stack direction={'column'} spacing={2}>
        {settings?.deliveryInstructionsEnabled && (
          <Alert severity="info">{settings.deliveryInstructionsText}</Alert>
        )}
        {typesLoading && (
          <Stack direction={'column'} spacing={2}>
            <Skeleton variant="rounded" width={'100%'} height={'74px'} />
            <Skeleton variant="rounded" width={'100%'} height={'74px'} />
          </Stack>
        )}
        <Stack direction={'column'} spacing={2}>
          {types?.includes(FulfillmentMethodType.PICKUP) && (
            <OptionCard
              iconComponent={<Place />}
              label={'Pickup Order'}
              onClick={handleClick(FulfillmentMethodType.PICKUP)}
              selected={selectedType === FulfillmentMethodType.PICKUP}
            >
              <PickupMethodContact
                index={0}
                onSubmit={handleSubmit}
                error={updateError ? getError(updateError) : undefined}
                submitLoading={loadingUpdate}
              />
            </OptionCard>
          )}
          {types?.includes(FulfillmentMethodType.SHIPPING) && (
            <OptionCard
              iconComponent={<LocalShipping />}
              label={'Ship Order'}
              onClick={handleClick(FulfillmentMethodType.SHIPPING)}
              selected={selectedType === FulfillmentMethodType.SHIPPING}
            >
              <ShippingMethodContent
                index={0}
                onSubmit={handleSubmit}
                error={updateError ? getError(updateError) : undefined}
                submitLoading={loadingUpdate}
              />
            </OptionCard>
          )}
        </Stack>
      </Stack>
    </FormContainer>
  )
}
