import React, { useCallback, useState } from 'react'
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@mui/material'
import _ from 'lodash'
import { useGetAutocompleteQuery } from '../redux/api/radarApi'
import { RadarAddress } from '../models/Radar'
import {
  AutocompleteElement,
  useFormContext,
} from 'react-hook-form-mui'

interface AddressAutocompleteFieldProps {
  name: string
  label: string
  readOnly?: boolean
  onSelect: (address: RadarAddress) => void
  onClear: () => void
}

export default function AddressAutocompleteField({
  name,
  label,
  readOnly,
  onSelect,
  onClear,
}: AddressAutocompleteFieldProps) {
  const [acValueDebounched, setAcValueDebounced] = useState<string>('')

  const { setValue } = useFormContext()

  const debounceFunc = useCallback(_.debounce(setAcValueDebounced, 500), [])

  const handleAutocompleteUpdate = (
    _: React.SyntheticEvent<Element, Event>,
    value: string | null,
    reason: AutocompleteInputChangeReason,
  ) => {
    if (reason === 'input') {
      debounceFunc(value || '')
      setValue(name, value || '')
    } else if (reason === 'clear') {
      onClear()
    }
  }

  const handleAutocompleteSelect = (
    _: React.SyntheticEvent,
    value: string | RadarAddress | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'selectOption') {
      if (value && typeof value !== 'string') {
        onSelect(value)
      }
    } else if (reason === 'clear') {
      onClear()
    }
  }

  const { data: addresses, isFetching: loadingAddresses } =
    useGetAutocompleteQuery(
      {
        query: acValueDebounched,
      },
      { skip: !acValueDebounched },
    )

  return (
    <AutocompleteElement
      required
      name={name}
      label={label}
      rules={{ required: true }}
      options={addresses || []}
      loading={loadingAddresses}
      autocompleteProps={{
        getOptionLabel: (option: RadarAddress | string) =>
          typeof option === 'string' ? option : option.addressLabel,
        renderOption: (props, option: string | RadarAddress) => (
          <li {...props}>
            {typeof option === 'string' ? option : option.formattedAddress}
          </li>
        ),
        readOnly,
        freeSolo: true,
        size: 'small',
        fullWidth: true,
        onInputChange: handleAutocompleteUpdate,
        onChange: handleAutocompleteSelect,
      }}
      transform={{
        input: (value: RadarAddress | string | null) => {
          if (typeof value === 'string') {
            return value
          } else if (value) {
            return value.addressLabel
          } else {
            return null
          }
        },
        output: (_, value: RadarAddress | string | null) => {
          console.log(value)
          if (typeof value === 'string') {
            return value
          } else if (value) {
            return value.addressLabel
          }
        },
      }}
    />
  )
}
