import React, { useContext, useEffect, useState } from 'react'
import { FulfillmentForm } from './FulfillmentActiveContent'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectFulfillments,
  selectParams,
  selectSettings,
  selectStatus,
} from '../../../redux/selectors/checkoutSelectors'
import {
  CheckoutStatus,
  RestrictAddressMode,
} from '../../../models/CheckoutSession'
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod'
import AddressSelector from '../AddressSelector'
import FulfillmentQuoteContent from './FulfillmentQuoteContent'
import { getValidFulfillments } from '../../../helpers/checkout'
import { AuthContext } from '../../../common/Auth'

interface ShippingMethodContentProps {
  index: number
  onSubmit: (data: FulfillmentForm) => Promise<void>
  error?: string
  submitLoading: boolean
}

export default function ShippingMethodContent({
  index,
  onSubmit,
  error,
  submitLoading,
}: ShippingMethodContentProps) {
  const params = useAppSelector(selectParams)
  const settings = useAppSelector(selectSettings)

  return (
    <>
      <AddressSelector
        label="Shipping"
        formPrefix={`fulfillments.${index}`}
        requirePhone={settings.isFulfillmentPhoneRequired}
        requireCompany={settings.isFulfillmentCompanyRequired}
        companyRename={
          settings.isFulfillmentCompanyRenameEnabled
            ? settings.fulfillmentCompanyRenameText
            : undefined
        }
        restrictAddressMode={settings.restrictShippingAddressMode}
        restrictAddressId={settings.restrictShippingAddressId}
        lockName={settings.isFulfillmentNameLocked}
        lockEmail={settings.isFulfillmentEmailLocked}
      >
        <FulfillmentQuoteContent
          index={index}
          label="Shipping"
          type={FulfillmentMethodType.SHIPPING}
          onSubmit={onSubmit}
          error={error}
          submitLoading={submitLoading}
        />
      </AddressSelector>
    </>
  )
}
