import React, { useState, useEffect,useContext, useRef, useLayoutEffect } from "react";
import { connect, useSelector } from "react-redux";
import { AuthContext } from '../../../common/Auth'
import { replaceOrigin } from "../../../util/helper";
import { _useToasts } from "../../../common/_useToasts";
import { getEnabledArtworkLocations } from "../../../util/products/productsAPIUtil";
import { useProductArtworkRestrictionState } from "../state/useProductArtworkRestrictionState";

const AddDecorationportal = ({
  artworkList,
  artworkListById,
  locationID,
  getDecoPrice,
  setCatalogueCustomRows,
  catalogueCustomRows,
  parentKey,
  artWorkID,
  setShowData,
  showData,
  temp,
  allowCustomDecoration,
  tempDecorationLocations,
  locationName,
  itemsRows,
  searchTextArtwork,
  artworkSetting,
  storeArtwork,
  selectedDecorationsList,
  artworkDuplicatesAllowed,
  artworkDuplicatesAllowedPro,
  product,
  decoExist,
  allowOneDec,
  isAdded,
  setIsAdded,
  selectedProductArtwork,
  decorationListOrder,
  preventAutoAttach,
  setSelectedLocationIndex,
  hideDecorationName,
  artworkCategoriesRestriction,
}) => {
  
  const { userType, user, userGroupsData, artworksByUserGroup, } = useContext(AuthContext)

  const [findProductArtvariationId, setFindProductArtvariationId] = useState('')
  const [productDesignId, setProductDesignId] = useState('')
  const [artWorkCount, setArtWorkCount] = useState(0)
  const [flag, setFlag] = useState(false)
  const [tempArtworkListById,setArtworkListById]=useState(artworkListById)
  const [ArtworkDuplicatesAllowed, setArtworkDuplicatesAllowed] = useState(true)
  const [artworkLocations, setArtworkLocations] = useState([]);
  const [showArtwork, setShowArtwork] = useState(false)
  const [isClicked, setIsClicked] = useState(0)
  let isQty = 0
  const {_addToast} = _useToasts();
  const [remainDecoLocation, setRemainDecoLocation] = useState(
    tempDecorationLocations,
    )
  const artworkGlobalIndicator = useSelector((state) => state.productData.artworkGlobalIndicator);
  const [tempArtworkList, setTempArtworkList] = useState(null);
  const artworkListRef = useRef(null);
  const {
    getStatusLocation,
  } =
  useProductArtworkRestrictionState({
    tempArtworkList,
    product,
    selectedDecorationsList,
    locationName,
    artworkLocations,
    artworksByCategories: artworkCategoriesRestriction
  });

  useEffect(() => {
    if(tempArtworkList === null) {
      setTempArtworkList(artworkListById)
    }
  }, [artworkListById])
  
  useEffect(() => {
    if ((artworkSetting && artworkGlobalIndicator) && artworkListById.length === 0) {
      artworkListById = artworkListById?.concat(artworkList?.filter(al => artworkListById?.every(ali => ali?.id != al?.id)));
    } else {
      if ( !artworkGlobalIndicator && artworkListById.length === 0 ) {
        artworkListById = artworkListById?.concat(artworkList?.filter(al=>artworkListById?.every(ali=>ali?.id != al?.id)));
      }
    }
  }, [artworkList,artworkListById, searchTextArtwork, artworkGlobalIndicator])
  
  
  useEffect(()=>{
	  let temp =artworkListById.filter((item) => {
		  if (item.artworkSource == 'aether' && (!(!!user?.userId) || artworkSetting)) {
			  if (allowCustomDecoration || artworkSetting) {
				  return item
			  }
		  } else {
			  return item
		  }
	  })
    temp = temp.filter((item =>item.singleUseOnly == '0'))
	let tempartworkListById=temp.filter((item => item.identity&&item.identity.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1 || item.designNo&&item.designNo.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1 || item.designTypeName&&item.designTypeName.toLowerCase().indexOf(searchTextArtwork.length>0?searchTextArtwork.toLowerCase():searchTextArtwork) !== -1));
  if(!!selectedProductArtwork) {
    tempartworkListById = tempartworkListById.filter(res => selectedProductArtwork !== res.id)
  }
  if(!!decorationListOrder && decorationListOrder.length > 0 && artworkListById.length > 0) {
    tempartworkListById = tempartworkListById.sort(function(a,b) {
      return decorationListOrder.indexOf(a.id) - decorationListOrder.indexOf(b.id);
    })
  }
	setArtworkListById(tempartworkListById)
  },[searchTextArtwork,artworkListById])

  useEffect(() => {
    if (artworkListById && artworkListById.length) {
      let locationCount = 0
      let tempartWorkCount = 0
      artworkListById.map((val, key) => {
        if (`${val?.productToDecorationMapping[0]?.location}-${parentKey}` === locationID && val?.productToDecorationMapping[0]?.isRequiredForStore == '1') {
          tempartWorkCount = tempartWorkCount + 1
          val.productToDecorationMapping[0].mapping.map((mapVal, mapkey) => {
            if (mapVal.color === catalogueCustomRows[parentKey][0]['color']) {
              locationCount = locationCount + 1
              setFindProductArtvariationId(mapVal.variationId)
              setProductDesignId(val?.id)
              setArtWorkCount(0)
              setFlag(!flag)
            }
          })

          if (locationCount > 1) {
            setShowData('yes')
            setFindProductArtvariationId('')
            setProductDesignId('')
            setFlag(!flag)
          }
        } else {
          setFindProductArtvariationId('')
          setProductDesignId('')
          setShowData('yes')
          setFlag(!flag)
          val.productToDecorationMapping.map((val) => {
            if (`${val.location}-${parentKey}` == locationID && val.isRequiredForStore == '0') {
              tempartWorkCount = tempartWorkCount + 1
              setTimeout(() => {
                //console.log('document.querySelectorAll(".artMain"): ', document.querySelectorAll(".artMain"), " ==== ", isAdded, " && ", preventAutoAttach);
                if (tempartWorkCount == 1 && !!document.querySelectorAll(".artMain")?.length && document.querySelectorAll(".artMain")?.length === 1 && !isAdded && !preventAutoAttach) {
                  setIsAdded(true)
                  let id = 'artew_' + parentKey + '1'
                  var link = document.getElementById(id);
                  link && link.click()
                }
              }, 2000)
            } else if (`${val.location}-${parentKey}` == locationID && val.isRequiredForStore == '1') {
              setTimeout(() => {
                const requiredDecoElements = document.querySelectorAll(".requiredDeco");
                if (requiredDecoElements.length === 1 && !isAdded && !preventAutoAttach) {
                  setIsAdded(true);
                  const id = `artew_`+ parentKey + '1';
                  const link = document.getElementById(id);
                  link && link.click();
                }
              }, 2000)
            }
          })
        }
      })
    }
  }, [artworkListById, locationID])

  useEffect(() => {
    if (productDesignId && artworkListById) {
      artworkListById &&
        artworkListById.map((artwork, index) => {
          if (artwork.id === productDesignId) {
            handleArtworkselection(artwork)
          }
        })
    }
  }, [productDesignId])

  useEffect(() => {
    if(artworkDuplicatesAllowedPro === true || artworkDuplicatesAllowedPro === false) {
        if(artworkDuplicatesAllowedPro === true)
            setArtworkDuplicatesAllowed(true)
        else
            setArtworkDuplicatesAllowed(false)
    } else if (artworkDuplicatesAllowed === true || artworkDuplicatesAllowed === false) {
        if(artworkDuplicatesAllowed === true)
            setArtworkDuplicatesAllowed(true)
        else
            setArtworkDuplicatesAllowed(false)
    }
  },[artworkDuplicatesAllowed, artworkDuplicatesAllowedPro])

  useEffect(() => {
    getArtworkLocations()
  }, [])

  useEffect(() => {
    let newLocation = []
    tempDecorationLocations &&
      tempDecorationLocations.map((val, index) => {
        let artworkSelected = null
        let checkLocation =
        decoExist && decoExist.find((d) => d.locationId === val.id && d.variationUniqID && d.variationUniqID !== "" && d.variationUniqID !== undefined && allowOneDec !== "2")
        if (checkLocation === undefined || checkLocation === '') {
          newLocation.push(val)
        }
      })
    setRemainDecoLocation(newLocation)
  }, [decoExist, tempDecorationLocations, itemsRows])

  const getArtworkLocations = () => {
    setShowArtwork(false);
    getEnabledArtworkLocations(product._id).then(response => {
        setArtworkLocations(response.data);
        setTimeout(() => {
          setShowArtwork(true);
        }, 250);
    }).catch(error => {
        console.log('error', error);
        _addToast('Error while loading data!', {
            appearance: "error",
            autoDismiss: true
        });
    })
  }

  const handleVariationselection = async (vari, artwork) => {
    let dPrice = await getDecoPrice(artwork.id)
    let decoPricedetails = []
    if (dPrice?.length > 0) {
      dPrice.map((priceArray) => {
        if (artwork?.design?.designType == priceArray.decoratorType) {
          decoPricedetails.push(priceArray)
        }
      })
    }

    let tempcatalogueCustomRows = catalogueCustomRows.filter(
      (rowGroupArray, groupkey) => {
        if (groupkey == parentKey) {
          rowGroupArray.filter((rowArray) => {
            rowArray.decorations &&
              rowArray.decorations.map((decoFilter) => {
                if (`${decoFilter.locationId}-${parentKey}` == locationID) {
                  if (decoPricedetails) {
                    decoFilter.decoPricedetails = decoPricedetails
                  }
                  decoFilter.variationUniqID = vari.design_variation_unique_id
                }
                return decoFilter
              })
            return rowArray
          })
        }
        return rowGroupArray
      },
    )
    setCatalogueCustomRows(tempcatalogueCustomRows)
    temp(parentKey, artwork.id, locationName)
  }
  
  var artwork_selected = null
  if (artWorkID) {
    artwork_selected =
      artworkListById.length && artworkListById.filter((f) => f.id === artWorkID)[0]

    if (artwork_selected?.design && artwork_selected?.design?.variation) {
      if (artwork_selected.design.variation.length == 1) {

        handleVariationselection(artwork_selected.design.variation[0])
      } else {
        artwork_selected.design.variation.map((vari, index) => {
          if (vari.design_variation_unique_id == findProductArtvariationId) {
            handleVariationselection(vari, artwork_selected)
            setShowData('no')
          }
          if (index == artwork_selected.length - 1) {
            if (showData === 'no') {
              setShowData('yes')
            }
          }
        })
      }
    }
  }

  const handleArtworkselection = async (artwork) => {
    if (setSelectedLocationIndex) {
        setSelectedLocationIndex(parentKey)
    }
    let dPrice = null
    if (artwork.design && artwork.design.variation) {
      dPrice = await getDecoPrice(artwork.id)
    }
    let decoPricedetails = []
    if (dPrice?.length > 0) {
      dPrice.map((priceArray) => {
        if (artwork?.design?.designType == priceArray.decoratorType) {
          decoPricedetails.push(priceArray)
        }
      })
    }
    let checkArtwork = true
    const location = getCurrentLocation()
    catalogueCustomRows[parentKey].forEach((row) => {
      checkArtwork = row.decorations.filter(decoration => decoration.artworkID === artwork.id && decoration.locationId === location).length > 0
    })
    if(!checkArtwork) {
      let decoration = (artwork.productToDecorationMapping || []).find(val => `${val.location}-${parentKey}` === locationID)
      decoration = decoration ? decoration : ((artwork.artworkLocation === locationName || artwork.artworkLocation === '') ? {
        locationName,
        location } : null)
      if(decoration) {
        const locationIndex = catalogueCustomRows[parentKey][0].decorations?.findIndex((deco) => deco.locationId === decoration.location)
        if(locationIndex < 0) {
          catalogueCustomRows[parentKey][0].decorations.push({
            location: decoration.locationName,
            locationId: decoration.location,
          })
        } else {
          catalogueCustomRows[parentKey][0].decorations[locationIndex] = {
            location: decoration.locationName,
            locationId: decoration.location,
          }
        }
      }
    }

    let tempcatalogueCustomRows = catalogueCustomRows.filter(
      (rowGroupArray, groupkey) => {
        if (groupkey == parentKey) {
          rowGroupArray.filter((rowArray) => {
            rowArray.decorations &&
              rowArray.decorations.map((decoFilter) => {
                if (
                  (`${decoFilter.locationId}-${parentKey}` == locationID &&
                    (!decoFilter.artworkID || decoFilter.artworkID == '')) ||
                  (`${decoFilter.locationId}-${parentKey}` == locationID &&
                    decoFilter.artworkID != artwork.id)
                ) {
                  decoFilter.artworkID = artwork.id
                  decoFilter.variationUniqID = null
                  if (artwork.design && artwork.design.variation) {
                    let checkLocation = []
                    if (artwork.productToDecorationMapping) {
                      checkLocation = artwork.productToDecorationMapping.filter(val => `${val.location}-${parentKey}` == locationID)
                      if (checkLocation?.length > 0) {
                        decoFilter.supplierDeco = checkLocation?.[checkLocation.length - 1]?.supplierDeco
                        decoFilter.isRequiredForStore = checkLocation?.[checkLocation.length - 1]?.isRequiredForStore
                      }
                    }
                    if (artwork.design.variation.length == 1  || (artwork.productToDecorationMapping || []).length === 0) {

                      if (decoPricedetails) {
                        decoFilter.decoPricedetails = decoPricedetails
                      }
                      decoFilter.variationUniqID =
                        artwork.design.variation[0].design_variation_unique_id
                      setShowData('no')
                      temp(parentKey, artwork.id, '', decoFilter.locationId)
                    } else {

                      let checkLocation = artwork.productToDecorationMapping.filter(val => `${val.location}-${parentKey}` == locationID)
                      if (checkLocation.length >= 1) {
                        let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter((val) => val.color == rowArray.color)

                        decoFilter.isRequiredForStore = checkLocation?.[checkLocation.length - 1]?.isRequiredForStore
                        if (getVariationId.length > 0) {
                          if (decoPricedetails) {
                            decoFilter.decoPricedetails = decoPricedetails
                          }
                          decoFilter.variationUniqID =
                            getVariationId[0].variationId
                          setShowData('no')
                          temp(parentKey, artwork.id, '', decoFilter.locationId)
                        }
                      } else {
                        let VarDat = !!artwork?.design?.variation && artwork.design.variation.filter(res => {                          
                          if (!!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word => word.toLowerCase()).includes(rowArray.color.toLowerCase()) && !!res?.itemImageThumbnail?.[0]) {
                            return res;
                          }
                        })
                        if(VarDat && VarDat.length > 0) {
                          decoFilter.variationUniqID = VarDat[0].design_variation_unique_id
                          setShowData('no')
                          temp(parentKey, artwork.id, locationName)
                        } else if(!!artwork?.design?.variation?.[0]) {
                          decoFilter.variationUniqID = artwork?.design?.variation?.[0]?.design_variation_unique_id
                          temp(parentKey, artwork.id, locationName)
                        }
                      }  
                    }
                  }
                } return decoFilter
              })
            return rowArray
          })
        }
        return rowGroupArray
      },
    )
    setCatalogueCustomRows(tempcatalogueCustomRows)
    // temp(parentKey, artwork.id)
  }


  useLayoutEffect(() => {
    let timeoutId = null;
    if (artworkListRef.current) {
      timeoutId = setTimeout(() => {
        const requiredDecoElement = artworkListRef.current.querySelector('.requiredDeco');
        if (requiredDecoElement) {
          artworkListRef.current.classList.add('requiredDiv');
        } else {
          artworkListRef.current.classList.remove('requiredDiv');
        }
      }, 2000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [artworkListRef.current, locationID]);

  const getCurrentLocation = () => {
    const inputString = locationID || '';
    return inputString.replace(/\b([a-zA-Z]*)-[0-9]\b/g, '$1');
  }

  return (
    <div className="col-md-12 mt-4">
      <div className="row pl-2 pr-4">
        {showData === 'yes' && (
          <div className="col-md">
            {remainDecoLocation.length > 0 &&
              <b>Select Artwork:</b>
            }
            <div>
              {productDesignId ? (
                <div
                  className="artworkList"
                  style={{ display: 'flex', overflowX: 'auto' }}
                  ref={artworkListRef}
                >
                  {tempArtworkListById.length && showArtwork &&
                    tempArtworkListById.map((artwork, index) => {
                      let imgUrl =""
                      let getCustomArtwotkByUser = true
                      let displayArtwork = true
                      let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId)
                      getCustomArtwotkByUser = (storeItem?.[0]?.userId === user.userId)
                      let getVariationId = artwork.productToDecorationMapping[0].mapping.filter(val => val.color == catalogueCustomRows[parentKey][0]['color']);
                    if(ArtworkDuplicatesAllowed === false) {
                        let isExist = selectedDecorationsList.filter(decoration => {
                            return decoration.artworkID == artwork.id
                        })
                        if(selectedDecorationsList.length > 0) {
                            if(isExist.length > 0) {
                                displayArtwork = false
                            }
                        }
                    }
                    let artmatchwithLocation = artwork.productToDecorationMapping.filter(val => `${val.location}-${parentKey}` == locationID)
                    let isExistLocations = null
                      if (selectedDecorationsList.length > 0) {
                        isExistLocations = selectedDecorationsList.filter(decoration => (
                          (decoration?.location === artwork?.artworkLocation ||
                            artwork.productToDecorationMapping.some(res => res.locationName === decoration.location)) &&
                          decoration?.isRequiredForStore === '1'
                        ));
                      }
                    if(displayArtwork === true && getStatusLocation(artwork.id)) {
                      if (getVariationId.length > 0) {

                        let imageUrl = artwork.design.variation.filter((val) => val.design_variation_unique_id == getVariationId[0].variationId)
                        if (imageUrl.length > 0) {
                          if (storeItem && storeItem.length > 0) {
                            if(storeItem?.[0]?.isEnable && getCustomArtwotkByUser){
                              imgUrl = imageUrl[0].itemImageThumbnail
                            } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                              imgUrl = imageUrl[0].itemImageThumbnail
                            } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId)) {
                              imgUrl = imageUrl[0].itemImageThumbnail
                            }
                          } else {
                            imgUrl = imageUrl[0].itemImageThumbnail
                          }
                        }
                      } else {
                        if((!(!!artwork?.artworkLocation) || artwork?.artworkLocation === locationName) && artwork?.design?.variation != "" && artwork.productToDecorationMapping == "" ) {
                          let VarDat = artwork.design.variation.filter(res=> {
                              if (!!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word => word.toLowerCase().trim()).includes(catalogueCustomRows[parentKey][0]['color'].toLowerCase()) && !!res?.itemImageThumbnail?.[0]) {
                                  return res
                              }  
                          })
                          if(VarDat && VarDat.length > 0) {
                            if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                              if(storeItem?.[0]?.isEnable && getCustomArtwotkByUser){
                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                              } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                imgUrl = VarDat?.[0].itemImageThumbnail?.[0]
                              } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                              }
                            } else {
                              if(artwork?.statusId === "5"){
                                imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                              }
                            }
                          } else {
                            if (storeItem && storeItem.length > 0 && artworkGlobalIndicator) {
                              if(storeItem?.[0]?.isEnable && getCustomArtwotkByUser){
                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                              } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                imgUrl = artwork?.design?.variation?.[0].itemImageThumbnail?.[0]
                              } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable) {
                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                              }
                            } else {
                              if(artwork?.statusId === "5"){
                                imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                              }
                            }
                          }
                        }
                      }
                      let isrequired = false
                      if (isExistLocations?.[0]?.artworkID == (artwork?.id || artwork.designId) && isExistLocations?.[0]?.isRequiredForStore == "1") {
                        isrequired = true
                      }
                        if (!!imgUrl && imgUrl.length > 0) {
                          isQty = isQty +1
                      // if (artmatchwithLocation.length > 0) {
                      return (
                        <div className={`${isrequired ? "artMain requiredDeco" : "artMain"}`} findparent={`parent${parentKey}`} key={'art2' + index}>
                          {(productDesignId ===
                            artwork?.designId || productDesignId === artwork?.id) && (
                              <div
                                className={'col-md artworkbox  artworkbox-selected decorows_selartwork'}
                                onClick={() => {
                                  handleArtworkselection(artwork)
                                }}
                                key={index}
                              >
                                <img
                                src={replaceOrigin(imgUrl)}
                                  className="img-fluid mt-3"
                                  alt="No Image"
                                style={{ maxHeight: '30px' }}
                                />
                                <label style={{ display: 'Block' }}>
                                  {artwork.designTypeName}
                                </label>
                                {!!!hideDecorationName && <label style={{ display: 'Block' }}>
                                  {artwork.identity}
                                </label>}
                            </div>
                            )}
                        </div>
                          )
                        }
                    }
                    })}
                </div>
              ) : (
                <div
                  className={`artworkList`}
                  ref={artworkListRef}
                  style={{ display: 'flex', overflowX: 'auto' }}
                >
                    {tempArtworkListById.length && showArtwork &&
                    showData == 'yes' ? tempArtworkListById.map((artwork, index) => {
                        let imgUrl =""
                        let getCustomArtwotkByUser = true
                        let displayArtwork = true

                        let storeItem = storeArtwork.filter(item => item.designId == artwork?.designId)
                        getCustomArtwotkByUser = (storeItem?.[0]?.userId === user.userId)
                        let checkLocation = artwork.productToDecorationMapping.filter(val => `${val.location}-${parentKey}` == locationID)
                      if(ArtworkDuplicatesAllowed === false) {
                        let isExist = selectedDecorationsList.filter(decoration => {
                            return decoration.artworkID == artwork.id
                        })
                        if(selectedDecorationsList.length > 0) {
                            if(isExist.length > 0) {
                                displayArtwork = false
                            }
                        }
                      }
                      let isExistLocations = null
                      if (selectedDecorationsList?.length > 0) {
                        isExistLocations = selectedDecorationsList.filter(decoration => (
                          (decoration?.location === artwork?.artworkLocation ||
                            artwork.productToDecorationMapping.some(res => res.locationName === decoration.location)) &&
                          decoration?.isRequiredForStore === '1'
                        ));
                      }
                      if(displayArtwork === true) {
                        //console.log('checkLocation: ', checkLocation, ' === ',storeItem, " === ", getCustomArtwotkByUser, " === ",artwork.designNo, " === ", storeItem?.[0]?.userId, " ==== ",user.userId);
                        if (checkLocation.length >= 1) {
                          let getVariationId = checkLocation[checkLocation.length - 1].mapping.filter(val => val.color == catalogueCustomRows[parentKey][0]['color']);

                          if (getVariationId.length > 0) {

                            let imageUrl = artwork.design.variation.filter((val) => val.design_variation_unique_id == getVariationId[0].variationId)
                            if (imageUrl.length > 0) {
                              if (storeItem && storeItem.length > 0) {
                                if(storeItem?.[0]?.isEnable && getCustomArtwotkByUser){
                                  imgUrl = imageUrl[0].itemImageThumbnail
                                } else if(storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration)) {
                                  imgUrl = imageUrl[0].itemImageThumbnail
                                } else if(artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId)) {
                                  imgUrl = imageUrl[0].itemImageThumbnail
                                }
                              } else {
                                imgUrl = imageUrl[0].itemImageThumbnail
                              }
                            }
                          }
                        } else {
                          if ( (!(!!artwork?.artworkLocation) || artwork?.artworkLocation === locationName) && artwork?.design?.variation != "" && artwork.productToDecorationMapping == "" ) {
                            let VarDat = artwork.design.variation.filter(res=>{
                              if ( !!res?.design_variation_color && res?.design_variation_color.split(',').length > 0 && res?.design_variation_color.split(',').map(word=>word.toLowerCase().trim()).includes(catalogueCustomRows[parentKey][0]['color'].toLowerCase()) && !!res?.itemImageThumbnail?.[0] ) {
                                return res
                              }
                            })
                            if ( VarDat && VarDat.length > 0 ) {
                              if ( storeItem && storeItem.length > 0 && artworkGlobalIndicator ) {
                                if ( storeItem?.[0]?.isEnable && getCustomArtwotkByUser ) {
                                  imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                } else if ( storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration) ) {
                                  imgUrl = VarDat?.[0].itemImageThumbnail?.[0]
                                } else if ( artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable ) {
                                  imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                }
                              } else {
                                if ( artwork?.statusId === "5" ) {
                                  imgUrl = VarDat?.[0]?.itemImageThumbnail?.[0]
                                }
                              }
                            } else {
                              if ( storeItem && storeItem.length > 0 && artworkGlobalIndicator ) {
                                if ( storeItem?.[0]?.isEnable && getCustomArtwotkByUser ) {
                                  imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                } else if ( storeItem?.[0]?.isEnable && (artworkSetting || allowCustomDecoration) ) {
                                  imgUrl = artwork?.design?.variation?.[0].itemImageThumbnail?.[0]
                                } else if ( artwork.artworkSource !== 'aether' && !(!!storeItem?.[0]?.userId) && storeItem?.[0]?.isEnable ) {
                                  imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                }
                              } else {
                                if ( artwork?.statusId === "5" ) {
                                  imgUrl = artwork?.design?.variation?.[0]?.itemImageThumbnail?.[0]
                                }
                              }
                            }
                          }
                        }
                        let customeIndex = 0
                        let artmatchwithLocation = artwork.productToDecorationMapping.filter(val => `${val.location}-${parentKey}` == locationID)
                        let isrequired = false
                        if(isExistLocations?.[0]?.artworkID == artwork?.id && isExistLocations?.[0]?.isRequiredForStore == "1") {
                          isrequired = true
                        }
                        if (!!imgUrl && imgUrl.length > 0 && getStatusLocation(artwork.id)) {
                        // if (artmatchwithLocation.length > 0) {
                          isQty = isQty +1
                          customeIndex = +1
                          return (
                            <div className={`${isrequired ? "artMain requiredDeco" : "artMain"}`} key={'artew_' + parentKey + index} findparent={`parent${parentKey}`} >

                            <div
                              className={'col-md artworkbox artworkbox-selected decorows_selartwork'}
                              onClick={() => {
                                handleArtworkselection(artwork)
                              }}
                              key={index}
                            >
                              <img
                                  id={'artew_' + parentKey + customeIndex}
                                  src={replaceOrigin(imgUrl)}
                                className="img-fluid mt-3"
                                alt="No Image"
                                style={{ maxHeight: '30px' }}
                              />
                              <label style={{ display: 'Block' }}>
                                {artwork.designTypeName}
                              </label>
                              {!!!hideDecorationName && <label style={{ display: 'Block' }}>
                                {artwork.identity}
                              </label>}
                            </div>
                          </div>
                          )
                        }
                      }
                    }): ''}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="row">
        {showData === 'yes' && (
          <div className="col-md">
            {artWorkID && artWorkID != '' && (
              <div className="col-md-12 p-0">
                <b>Select Variation:</b>
                {artwork_selected && (
                  <div
                    className="col-md-12"
                    style={{ display: 'flex', overflowX: 'auto' }}
                  >
                    {artwork_selected.design.variation &&
                      artwork_selected.design.variation.map((vari, index) => {
                        return (
                          <div
                            className={'col-md artworkbox decorows_selartwork'}
                            key={'vari' + index}
                            onClick={() =>
                              handleVariationselection(vari, artwork_selected)
                            }
                          >
                            <img
                              src={
                                vari.itemImageThumbnail
                                  ? replaceOrigin(vari.itemImageThumbnail)
                                  : ''
                              }
                              className="img-fluid mt-2"
                              alt="No Image"
                              style={{ maxHeight: '100px' }}
                            />
                            {/*<label style={{ display: 'Block' }}>
                              {vari.design_variation_color}
                            </label>*/}
                            <label style={{ display: 'Block' }}>
                              {vari.design_variation_title}
                            </label>
                          </div>
                        )
                      })}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
  }
}
const mapStateToProps = (state) => {
  return {
    artworkList: state.productData.artworkList,
    artworkListById: state.productData.artworkListById,
    artworkCategoriesRestriction: state.productData.artworkCategoriesRestriction,
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDecorationportal);

