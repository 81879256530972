import { useToasts } from 'react-toast-notifications';
import { useAppSelector } from '../redux/hooks';
import {selectHeaderOffset} from '../redux/selectors/checkoutSelectors'
export const _useToasts = () => {
  const { addToast, removeToast, removeAllToasts } = useToasts();
  const offset = useAppSelector(selectHeaderOffset)

  const _addToast = (message, options = {}) => {
    const defaultOptions = {
      appearance: 'success',
      autoDismiss: true,
      placement: 'top-right',
      style: { top: offset, position: 'fixed', right: '10px'}
    };
    // default is success, for error and warning you need to pass appearance and there are other various default options
    addToast(message, { ...defaultOptions, ...options }); // base method
  };

  return {
    _addToast,
    removeToast,
    removeAllToasts,
  };
};