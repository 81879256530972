import Decimal from 'decimal.js'

export function formatCurrency(
  symbol: string,
  amount: number | Decimal,
  showPositive?: boolean,
  decimals: number = 2,
) {
  if (typeof amount === 'number') {
    amount = new Decimal(amount)
  }
  amount = amount.toDP(decimals)
  const format = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const formattedAmount = format.format(amount.toNumber())
  if (amount.isNeg()) {
    return `-${symbol}${amount.abs().toFixed(decimals)}`
  } else if (amount.isPos() && showPositive) {
    return `+${symbol}${formattedAmount}`
  } else {
    return `${symbol}${formattedAmount}`
  }
}

export function formatNumber(number: number) {
  return new Intl.NumberFormat('en-US').format(number)
}

export function getPoints(
  value: number | Decimal,
  pointsValue: number,
  showDecimals: boolean,
) {
  if (typeof value === 'number') {
    value = new Decimal(value)
  }
  const raw = value.div(pointsValue)
  if (showDecimals) {
    return raw.mul(100).ceil().div(100).toNumber()
  }
  return raw.ceil().toNumber()
}

export function formatPoints(
  value: number | Decimal,
  pointsValue: number,
  showDecimals: boolean,
  pointsLabelPlural: string,
  pointsLabelSingular: string,
) {
  const points = getPoints(value, pointsValue, showDecimals)
  return points === 1
    ? `${points} ${pointsLabelSingular}`
    : `${points} ${pointsLabelPlural}`
}
