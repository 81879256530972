import { AccessType } from "./AccessType";
import { Charge } from "./Charge";
import { ItemRow } from "./ItemRow";
import { PaymentMethodType } from "./PaymentMethodType";
import { RefWithNo } from "./Ref";

export interface ItemProduct {
    id: string;
    code: string;
    no: string;
    name: string;
    sku: string;
}

export interface PaymentRestriction {
  type: AccessType;
  methodTypes: PaymentMethodType[];
}

export interface OrderItem {
    id: string;
    product: ItemProduct;
    vendor: RefWithNo;
    quantity: number;
    tax: number;
    customerDescription: string;
    vendorDescription: string;
    inhandDate: string;
    type: string;
    productLineItemType: string;
    doNotCalculateAtOrder: number;
    hide: string;
    dropDown: string;
    accountCode: string;
    itemType: string;
    poType: string;
    lineItemNote: string;
    quoteCustomImage: string[];
    uomSetRef: string;
    inhouseId: string;
    netPrice: string;
    showAttribSize: string;
    showAttribColor: string;
    warehouse: string;
    taxable: number;
    payCommission: number;
    uniqueId: string;
    isCustomerSupplied: number;
    aetherPriceDisabled: number;
    autoPulled: string;
    rows: ItemRow[];
    charges: Charge[];
    aetherData: {
      showColor: boolean;
      showSize: boolean;
      displayPrice: number;
      displayTotalPrice: number;
      displayTax: number;
      paymentRestriction: PaymentRestriction;
      decimalsToShow: number;
      paymentApplied: number;
      aetherItemType: AetherItemType;
      discount: number;
      isPrintProduct: boolean;
      printData?: {
        images: string[]
        form: Record<string, string>
      }
    }
}

export enum AetherItemType {
  PRODUCT = 'product',
  SHIPPING = 'shipping',
}
