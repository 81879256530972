import axios from 'axios';
import {
    X_TENANT_ID,
    SETTINGS,
    ARTWORK_LOCATIONS,
    ALERT_MESSAGE_PRODUCT,
    ADVANCE_DELETE_ARTWORK,
    ADVANCE_STORE_DETAILS,
    GET_PRODUCT,
    UPLOADIMAGE, GET_PRODUCTS_BY_ID,
    ARTWORK_CATEGORIES
} from '../../common/ActionUrl';
const requestOptions = {
    headers: { 'X-TENANT-ID': X_TENANT_ID },
}
const getProducts = (limit = 50, offset = 0, order = "productName", orient = "asc", filters, apiCount, url)  => {
    let catList = filters.categories ? filters.categories : []
    let tagList = filters.tags ? filters.tags : []
    let colorList = filters.colors ? filters.colors : []
    let sizeList = filters.sizes ? filters.sizes : []
    let userGroupList = filters.userGroups ? filters.userGroups : []
    let href = window?.location?.href?.split('/');
    let data = {
        "isDeleted": 0,
        "limit": limit,
        "offset": offset,
        "order": order,
        "orient": orient,
        "categories": catList,
        "tags": tagList,
        "colors": colorList,
        "sizes": sizeList,
        "userGroupList": userGroupList,
        "isFeatured": filters.isFeatured ? filters.isFeatured : false,
        "query": filters.query ?? "",
        "isAll": href && href.length > 0 && href[href.length - 1] === 'products' && userGroupList && userGroupList.length > 0,
    }
    return axios.post(url, data, requestOptions);
}

export const getFeatureProducts = (limit = 50, offset = 0, order = "productName", orient = "asc", filters) => {
    let catList = filters.categories ? filters.categories : []
    let tagList = filters.tags ? filters.tags : []
    let colorList = filters.colors ? filters.colors : []
    let sizeList = filters.sizes ? filters.sizes : []
    let userGroupList = filters.userGroups ? filters.userGroups : []
    let data = {
        "isDeleted": 0,
        "limit": limit,
        "offset": offset,
        "order": order,
        "orient": orient,
        "categories": catList,
        "tags": tagList,
        "colors": colorList,
        "sizes": sizeList,
        "userGroupList": userGroupList,
        // "isFeatured": filters.isFeatured ? filters.isFeatured : true,
        "productName": filters.productName ? filters.productName : "",
        "id" : filters?.id ? filters?.id :""
    }
    let url = `/api/v1/front/products/getFetData`;
    return axios.post(url, data, requestOptions);
};


export const fetchFeaturedProducts = (limit = 50, offset = 0, order = "productName", orient = "asc", filters, apiCount) => {
    return getProducts(limit, offset, order, orient, filters, apiCount, '/api/v1/front/products')
}

export const getTagProducts = (limit = 50, offset = 0, order = "productName", orient = "asc", filters, apiCount) => {
    return getProducts(limit, offset, order, orient, filters, apiCount, '/api/v1/front/products/tagProducts')
};

export const getProductStock = ( productId, retryLive = 0 ) => {
    const data = {
        meta: {
            offset: 0,
            limit: 30,
            orient: "desc",
            type: true,
            completed: false,
            order: "productId"
        },
        params: {
            color: "No Color",
            live: 0,
            retryLive: 0,
        }
    }
    data.params.retryLive = retryLive
    return axios.post(`api/v1/advance/products/stock/${productId}`, data)
}


export const fetchDecorationLocations = (params) => {
    return axios.get(`api/v1/advance/products/locations`, {params});
};


export const fetchArtworksById = async (filterData) => {
    if(!!filterData.account_id)
    {
        const params = {
            'source': 'cp',
            'limit': '1000',
            'term[statusName]': 'Done',
            'term[isInactive]': '0',
            'order': "id",
            'orient': "asc",
            'account_id': filterData?.account_id ? filterData?.account_id : undefined,
            'product_id': filterData?.product_id ? filterData?.product_id : undefined,
            'ids': filterData?.ids ? filterData?.ids : undefined,
            'user_id': filterData.userId,
            'categories': filterData.categories,
            'design': filterData.design,
            'restrict': filterData.restrict || undefined,
            'products': filterData?.products ? filterData?.products : undefined

        }
        return axios.post(`api/v1/advance/products/artworksById`, params)
    }
    else
    {
        console.log('fetchArtworksById - account id is missing')
        return [];
    }
}

export const fetchStoreDetails = () => {
    return axios.get(ADVANCE_STORE_DETAILS);
};
export const getKitProducts = (id) => {
    return axios.get(`${GET_PRODUCT}${id}/kitProducts`);
};
export const getApittoken = () => {
    return axios.get(SETTINGS);
};
export const fetchProductInventory = (data) => {
       let url = `api/v1/advance/products/live-inventory/${data.productId}`;
       return axios.post(url, data);
};
export const getProductDealDay = (data) => {
    let url = `/api/v1/front/products/getProducts/` + data;
    return axios.get(url, requestOptions);
};

export const fetchProduct = (id) => {
    return axios.get(`${GET_PRODUCT}${id}`, requestOptions);
};

export const getMultipleProduct = (data) => {
    return axios.post(`${GET_PRODUCTS_BY_ID}`, data, requestOptions);
};


export const fetchDstProductInventory = (data) => {
    const params = {
        offset: "0",
        limit: "50",
        order: 'productId',
        orient: 'desc',
        id: data.productId,
        type: true,
        completed: false,
    }
    return axios.post(`api/v1/advance/products/dst-inventory/${data.productId}`, params)

}
export const deleteArtWork = (data) => {
    let URL = window.advanceApiurl;
    if (window.advanceApiurl == undefined || window.advanceApiurl == "undefined") {
        URL = ""
    }
    let url = URL + `/api/v1/artwork/` + data.id;
    let params = {url: url};
 	return axios.post(ADVANCE_DELETE_ARTWORK, params, requestOptions);
};
export const getDecoratorTypes = () => {
    return axios.get('api/v1/advance/products/decorator-types');
};
export const getDecorationPrice = (decodata) => {
    const params = {};
    for (let key in decodata) {
        if (typeof (decodata[key]) == "object") {
            for (let dkey in decodata[key]) {
                params[`term[${dkey}]`] = decodata[key][dkey]
            }
        } else {
            params[key] = decodata[key]
        }
    }
    return axios.post(`api/v1/advance/products/decoration/price`, params)
};


export const getVivaTemplates = () => {
    return axios.get('api/v1/variable-print/templates/list')
}


export const getPrintSettings = (productId) => {
    const url = `api/v1/variable-print/${productId}/format`
    return axios.get(url)
}
export const getVivaFields = (productId, sessionId) => {
    const url = `api/v1/variable-print/fields/${productId}/${sessionId}`
    return axios.get(url)
}


export const updateProductPrintVariable = (settingsId, values) => {
    const url = `api/v1/variable-print/values/${settingsId}`
    return axios.put(url, values)
}

export const getProofImages = (data) => {
    const url = `api/v1/variable-print/image/watermark`
    return axios.post(url, data)
}

export const getEnabledArtworkLocations = (productId) => {
    return axios.get(`${ARTWORK_LOCATIONS}${productId}`)
}

export const getProductDecorationPopup = (productId) => {
    return axios.get(`${ALERT_MESSAGE_PRODUCT}${productId}`);
} 


export const uploadImage = (formData) => {
    return axios.post(UPLOADIMAGE, formData, requestOptions)
}

export const getCategoriesArtworkRestriction = (categories) => {
    return axios.post(ARTWORK_CATEGORIES, categories, requestOptions)
}