import React, { useContext, useMemo, useState } from 'react'
import { Alert, Box, Dialog, Stack } from '@mui/material'
import PaymentRequestContent from './PaymentRequestContent'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  selectBalanceDue,
  selectParams,
  selectPaymentRequests,
  selectRemainingBalanceDue,
  selectSessionId,
  selectSettings,
  selectStatus,
} from '../../../redux/selectors/checkoutSelectors'
import { AetherPaymentRequest, PaymentResult } from '../../../models/Payment'
import _, { uniqueId } from 'lodash'
import {
  useSubmitOrderMutation,
  useSubmitPaymentsMutation,
} from '../../../redux/api/checkoutApi'
import { LoadingButton } from '@mui/lab'
import { getError } from '../../../helpers/checkout'
import { CheckoutStatus } from '../../../models/CheckoutSession'
import { PaymentMethodType } from '../../../models/PaymentMethodType'
import {
  addPaymentRequest,
  clearPaymentRequests,
  updatePaymentRequest,
  updatePaymentRequests,
} from '../../../redux/reducers/checkoutReducer'
import OtpDialog from './OtpDialog'
import { OtpRequest } from '../../../models/Otp'
import { cartItemData } from '../../../redux/actions/cartActions'
import { getCartById } from '../../../util/cart/cartAPIUtil'
import { AuthContext } from '../../../common/Auth'
import WholePageSpinner from '../../../common/WholePageSpinner'
import { aetherApi } from '../../../redux/api/aetherApi'

export default function PaymentActiveContent() {
  const { user, ipV4, getUserPoints } = useContext(AuthContext)
  const dispatch = useAppDispatch()
  const params = useAppSelector(selectParams)
  const settings = useAppSelector(selectSettings)
  const sessionId = useAppSelector((state) => selectSessionId(state, params))
  const status = useAppSelector((state) => selectStatus(state, params))
  const paymentRequests = useAppSelector(selectPaymentRequests)
  const remainingBalance = useAppSelector((state) =>
    selectRemainingBalanceDue(state, params, null),
  )
  const [paymentErrors, setPaymentErrors] = useState<
    { method: PaymentMethodType; message: string }[]
  >([])
  const [selected, setSelected] = useState<number | null>(null)
  const [openOtpDialog, setOpenOtpDialog] = useState(false)
  const [newRequestKey, setNewRequestKey] = useState(uniqueId())

  const [
    submitPayments,
    { isLoading: submitPaymentsLoading, error: submitPaymentsError },
  ] = useSubmitPaymentsMutation()
  const [
    submitOrder,
    { isLoading: submitOrderLoading, error: submitOrderError },
  ] = useSubmitOrderMutation()

  const onSubmit = ({
    index,
    data,
  }: {
    index: number | null
    data: AetherPaymentRequest
  }) => {
    if (index === null) {
      dispatch(addPaymentRequest(data))
    } else {
      dispatch(updatePaymentRequest({ index, request: data }))
    }
    setNewRequestKey(uniqueId())
    setSelected(null)
  }

  const handleSelect = (index: number | null) => () => {
    setSelected(index)
  }

  const handleOtpDialogContinue = (otpReq: OtpRequest) => {
    setOpenOtpDialog(false)
    placeOrder(otpReq)
  }

  const handleOtpDialogClose = () => {
    setOpenOtpDialog(false)
  }

  const handlePlaceOrder = () => {
    const needOtp = paymentRequests.some((request) => request.otpEnabled)
    if (needOtp) {
      setOpenOtpDialog(true)
    } else {
      placeOrder()
    }
  }

  const placeOrder = async (otpReq?: OtpRequest) => {
    try {
      if (!sessionId || !params) return
      const newPaymentRequests = []
      const newPaymentErrors = []
      let submit = true
      if (status === CheckoutStatus.PENDING_PAYMENT) {
        const { paymentResponses, session } = await submitPayments({
          sessionId,
          userId: params.userId,
          paymentRequests,
          otpReq,
        }).unwrap()
        if (user) {
          dispatch(aetherApi.util.invalidateTags(['BudgetHistory', 'Budgets']))
          getUserPoints();
        }
        if (session.status === CheckoutStatus.PENDING_PAYMENT) {
          // Update form with failed payments
          if (paymentResponses.length !== paymentRequests.length) {
            throw new Error(
              'Payment response count does not match request count',
            )
          }
          for (let i = 0; i < paymentResponses.length; i++) {
            const response = paymentResponses[i]
            if (response.result !== PaymentResult.SUCCESS) {
              newPaymentRequests.push(paymentRequests[i])
              if (
                response.result === PaymentResult.FAILURE ||
                response.result === PaymentResult.REVERTED
              ) {
                newPaymentErrors.push({
                  method: response.methodType,
                  message:
                    response.message ?? 'Unknown error processing payment',
                })
                if (selected === null) {
                  setSelected(i)
                }
              }
            }
          }
          dispatch(updatePaymentRequests(newPaymentRequests))
          setPaymentErrors(newPaymentErrors)
          submit = false
        }
      }
      if (submit && params) {
        dispatch(clearPaymentRequests())
        setPaymentErrors([])
        await submitOrder({ sessionId, userId: params.userId }).unwrap()
        const cartData = await getCartById(user, ipV4)
        dispatch(cartItemData(cartData ?? { _id: null, cartData: [] }))
      }
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Box>
      {submitPaymentsLoading && <WholePageSpinner text="Submitting Payments" />}
      {submitOrderLoading && <WholePageSpinner text="Submitting Order" />}
      <OtpDialog
        open={openOtpDialog}
        onClose={handleOtpDialogClose}
        onContinue={handleOtpDialogContinue}
      />
      <Stack direction={'column'} spacing={2}>
        {status !== CheckoutStatus.PENDING_SUBMISSION &&
          settings?.paymentInstructionsEnabled && (
            <Alert severity="info">{settings.paymentInstructionsText}</Alert>
          )}
        {paymentRequests.map((_, index) => (
          <PaymentRequestContent
            key={index}
            index={index}
            errorMessage={paymentErrors[index]}
            editing={selected === index}
            onEdit={handleSelect(index)}
            onSubmit={onSubmit}
          />
        ))}
        {selected === null && remainingBalance > 0 && (
          <PaymentRequestContent
            key={newRequestKey}
            index={null}
            editing={true}
            onEdit={handleSelect(null)}
            onSubmit={onSubmit}
          />
        )}
        <LoadingButton
          variant="contained"
          size={'large'}
          disabled={selected !== null || remainingBalance !== 0}
          loading={submitPaymentsLoading || submitOrderLoading}
          onClick={handlePlaceOrder}
        >
          Place Order
        </LoadingButton>
        {submitPaymentsError && (
          <Alert severity="error">
            Error Processing Payments: {getError(submitPaymentsError)}
          </Alert>
        )}
        {submitOrderError && (
          <Alert severity="error">
            Error Submitting Order: {getError(submitOrderError)}
          </Alert>
        )}
      </Stack>
    </Box>
  )
}
