import { Address, AddressContact } from './Address'
import { CheckoutQuestion } from './CheckoutQuestion'
import { AddressSource, FulfillmentQuoteMethod } from './FulfillmentQuote'
import { OrderItem } from './OrderItem'
import { OrderPayment } from './Payment'

export interface Fulfillment {
  contact: AddressContact
  address?: Address
  savedAddressId?: string
  addressSource?: AddressSource
  method: FulfillmentQuoteMethod
  minFulfillmentDate: string
  maxFulfillmentDate: string
  quoteId: string
}

export interface Order {
  id?: string;
  no?: string;
  inhandDate: string
  items: OrderItem[]
  aetherData: {
    firstName: string
    lastName: string
    email: string
    questions: CheckoutQuestion[]
    displaySubtotal: number
    displayTax: number
    displayTotal: number
    displayShipping: number
    displayBalanceDue: number
    decimalsToShow: number
    displayConvenienceFee: number
    displayConvenienceFeeTax: number
    discount: number
    fulfillments: Fulfillment[]
    payments: OrderPayment[],
    coupon?: Coupon,
    orderNote?: string
    holdStatus?: string
  },
  tax: {
    rate: number;
  }
}

export enum OrderHoldStatus {
  HOLD = 'hold',
  NO_HOLD = 'noHold',
}

export interface Coupon {
  couponCode: string;
  amountType: boolean;
  discount: number;
}

