if (process.env.NODE_ENV) {
    if (process.env.NODE_ENV === 'development') {
        window.configEnv.WEBSTORESERVER = 'http://localhost:3333'
        // window.configEnv.WEBSTORESERVER = 'https://deadline.aether-stores.io/public/'
    }
}

// multitenant key
let hostname = window.location.hostname
console.log('hostname ', hostname);

if (hostname === 'localhost') {
    hostname = 'localhost.'
}


export const X_TENANT_ID = hostname.substr(0, hostname.indexOf('.'));

const URL = window.configEnv?.WEBSTORESERVER
export const API_URL = URL;
//Settings Url
export const SETTINGS = '/api/v1/settings/front';
export const SETTINGS_URL = '/api/v1/settings/';
export const USER_GROUPS = '/api/v1/auth/user-groups/';
//Banners Url
export const BANNER = '/api/v1/banners/';
export const GET_ALL_BANNER = '/api/v1/banners/admin/';
export const GET_BANNER = '/api/v1/banners/userFront';
export const EDIT_BANNER = '/api/v1/banners/';
//Login Or Register Url
export const SIGNUP = '/api/v1/auth/signup';
export const GOOGLESIGNUP = '/api/v1/auth/googlesignup';
export const SSOSIGNIN = '/api/v1/auth/ssosignin';
export const SSOTOKENSIGNIN = '/api/v1/auth/sso-token';
export const SIGNIN = URL + '/api/v1/auth/signin';
export const FORGOTPASSWORD = '/api/v1/auth/forgotPassword';
export const SETNEWPASSWORD = URL + '/api/v1/auth/setNewPassword';
//Email Notification template
export const NOTIFICATION = '/api/v1/notifications';
export const CURRENTURL = process.env.PUBLIC_URL;
//Theme
export const THEME_URL = '/api/v1/theme-settings/front/';
export const CATEGORIES_URL = '/api/v1/categories';
export const ORDERDETAILS_URL = '/api/v1/orderDetails';
export const CATEGORIES_USER_URL = '/api/v1/categories/user';
export const GET_TAGS_URL = '/api/v1/front/product-tags';
export const STATIC_PAGE_URL = '/api/v1/static-pages';
export const CUSTOM_CSS_URL = '/api/v1/custom-css';

export const UPLOADIMAGE = '/api/v1/uploadImage';
export const UPLOADFILES = '/api/v1/uploadImage/uploadDocuments';
export const ACTIONGRID = '/api/v1/action-grid/';
export const TESTIMONIAL = 'api/v1/testimonial/';
export const BRAND_LOGOS = '/api/v1/brand-logo/';
export const PROMOTIONALPRODUCT_CAT = '/api/v1/promotional-product/';
export const TAGS_URL = '/api/v1/product-tags/getbyids/';
export const ALL_TAGS_URL = '/api/v1/product-tags';
export const GET_PRODUCT = '/api/v1/front/products/';
export const GET_PRODUCTS_BY_ID = '/api/v1/front/products/get-multiple/';
export const IMAGESLIDER = '/api/v1/image-slider/';
export const CATEGORYSLIDER = '/api/v1/category-slider/';
export const USER = '/api/v1/user/';
export const USER_EDIT = '/api/v1/auth/edit/';
export const USER_ADDRESS = '/api/v1/user-address/';
export const ALL_DISTRICT = '/api/v1/districts/';
export const GET_USER_POINTS = '/api/v1/user-points/points/';
export const GET_USER_POINTS_HISTORY = '/api/v1/user-points/history/';
export const PAYMENT_SETTING_URL = '/api/v1/payment-settings/front/';
export const USER_ADDRESS_ALL = '/api/v1/user-address/list/';
export const USER_COSTCENTER_ALL = '/api/v1/user/costlist/';
export const GET_USERGROUP_ARTWORK = '/api/v1/user/groupsartworks/';
export const GET_CATEGORY_ARTWORK = '/api/v1/categories/categoriesartworks/';
export const USER_ALLGROUP_PAYMENTMETHOD = '/api/v1/user/userallgroupallpayment/';
export const DEFAULT_GROUP_PAYMENTMETHOD = '/api/v1/user/defaultgroupallpayment/default';
export const DEFAULT_USERGROUP = '/api/v1/user/groups/default';
export const CARDCONNECT_PAYMENT = '/api/v1/payment/cardconnect';
export const PAYGATE_PAYMENT = '/api/v1/payment/paygate';
export const PAYGATE_RESULTS = '/api/v1/payment/paygate/result';
export const PAYGATE_REDIRECT = '/api/v1/payment/paygate/redirect';
export const HEARTLAND_PAYMENT = '/api/v1/payment/heartland';
export const COUPON_PAYMENT = '/api/v1/payment/coupon';
export const SEARCHPRODUCT = '/api/v1/front/products/front/searchProduct';
export const STRIPE_PAYMENT = '/api/v1/payment/stripe';
export const ORDER_CONFIRMATION = '/api/v1/payment/notify';
export const POINTS_PAYMENT = '/api/v1/payment/points';
export const PURCHASE_ORDER_PAYMENT = '/api/v1/payment/directpurchaseOrderPayment';
export const DIRECTBILL_PAYMENT = '/api/v1/payment/directPayment';
export const DIRECTBUDGET_PAYMENT = '/api/v1/payment/directBudget';
export const GIFTCARD_PAYMENT = '/api/v1/payment/giftCard';
export const VOUCHER_PAYMENT = '/api/v1/payment/voucher';
export const CHANGESTATUS_ORDER = '/api/v1/payment/orders/status';
export const PRODUCTTAG = '/api/v1/front/product-tags';
export const COLOR = '/api/v1/color';
export const NEWCOLOR = '/api/v1/color/new'
export const CATEGORY = '/api/v1/front/categories/include-size-color';
export const CATEGORY_BY_IDS = '/api/v1/front/categories/include-size-color/list';
export const PRODUCTSIZE = '/api/v1/product-size/';
export const CODEICON = 'https://icons-for-free.com/iconfiles/png/512/remove+icon-1320184982863027796.png'
export const REVIEW = '/api/v1/review/';
export const FRONTREVIEW = '/api/v1/front/review';
export const RELATED_PRODUCTS = '/api/v1/products/relatedproducts';
export const PRODUCTS = "/products";
export const USERGROUPS = "api/v1/user-groups/usergroupslist";
export const BREADCRUMBS = "api/v1/bread-crum/";
export const GET_USERGROUP_DISCOUNT = 'api/v1/user-groups/usergroupsDiscount';
export const POST_GIFT_CARD_PRODUCT = "api/v1/gift-cards-notification";
export const UPLOAD_GIFT_CARD_PRODUCT = "api/v1/gift-cards-notification/upload/";
export const CONTACT_US= "api/v1/front/contact-us"
export const STATIC_FORM_URL = URL +'/api/v1/static-pages';
export const CO_SHIPPING_LOCATION = '/api/v1/co-shipping-location/getAll';
export const LIST_CO_SHIPPING_LOCATION = '/api/v1/co-shipping-location/checkout/';
export const GET_ADVANCE_LOCATION = '/api/v1/advance/accounts/';
export const GETSHIPPINGIDFROMUSERGROUP = "api/v1/user-groups/usergroupsShppingList/ByGroupId";
export const GET_CATEGORIES_BY_USER_GROUPS = "api/v1/user-groups/categories";
export const GET_USER_BUDGET = '/api/v1/user-budget/user/';
export const GET_USER_COMPANY = '/api/v1/auth/company/';
export const GET_NON_TAXABLE = '/api/v1/auth/non-taxable/'
export const GET_USER_BUDGET_HISTORY = '/api/v1/user-budget-history/user-usage/';
export const VIVA_API_URL = 'https://vd10.viva.de/antera/api'
export const QUESTIONS = '/api/v1/question';
export const CHECKOUT_TOKEN = '/api/v1/auth/checkout/token';
export const CHECKOUT_OTP = '/api/v1/auth/checkout/otp';
export const CHECK_CONVENIENCE_FEE_TAX = '/api/v1/payment/product/conveniencefee';

//Cart 
export const CART_URL = '/api/v1/cart';
export const ADVANCE_DELETE_ARTWORK = 'api/v1/advance/deleteArtwork';
export const ADVANCE_STORE_DETAILS = 'api/v1/advance/gateway/store-details';
export const ADVANCE_ORDER= 'api/v1/advance/orders';
export const COST_CODE = '/api/v1/cost-code/';
export const SESSION = '/api/v1/auth/session/';
export const PAYMENTS_ORDER = '/api/v1/payment/history/';
export const PUNCH_OUT_ORDER = '/api/v1/handler-punch-out/checkout';
//Comments
export const COMMENT_URL = '/api/v1/blogComments/';

export const STORE_SETTING = '/api/v1/store/'
export const GET_STORE_ADMIN = '/api/v1/user/getStoreAdmin/'
export const GET_ORDER_DETAILS_BYID = '/api/v1/orderDetails/';
export const APPROVEMENT_BUDGET = '/api/v1/budgetApprovement/'
export const APPROVE_BUDGET = '/api/v1/payment/approvementBudget'

export const STORE_ADMIN_LOGIN = '/api/v1/auth/impersonateUser'
export const ACTIVATE_USER = '/api/v1/auth/activate'
export const USER_COUPONS = '/api/v1/coupon/'
export const CHECKOUT_COUPON = '/api/v1/coupon/validate';
export const ORDER_COUPON = '/api/v1/payment/coupon/order';
export const SHIP_ENGINE_REQUEST = '/api/v1/shipEngine/get-shipping';
export const SHIP_STATION_REQUEST = '/api/v1/shipStation/getShipping';
export const GIFT_CARDS = '/api/v1/gift-cards'
export const VOUCHERS = '/api/v1/vouchers'


export const UPLOAD_CUSTOM_ARTWORK = '/api/v1/artworkdata/uploadArtworkImage';

export const BRAND_LOGO_URL = '/api/v1/brand-logo/';
export const ARTWORK_LOCATIONS = '/api/v1/artworkLocation/';
export const ARTWORK_USER_GROUP = '/api/v1/artworkLocation/userGroup/'
export const ARTWORK_CATEGORIES = '/api/v1/artworkLocation/category/'
export const USER_GROUP_DATA = '/api/v1/user-groups/getList/'
export const ALERT_MESSAGE_PRODUCT = '/api/v1/product-decoration/';
export const ORDER_CREATE = 'api/v1/advance/orders/create';
export const STORE_ORDER_CREATE = 'api/v1/storeOrder/create';
export const TAX_URL = '/api/v1/orders/tax-rate';
export const TAX_JAR_URL = '/api/v1/taxjar/taxRate'
export const TAX_CALCULATION_URL = '/api/v1/tax/calculate'
export const ORDER_PRODUCT_SETTINGS = '/api/v1/order-product-settings/';
export const USER_GROUPS_USER = 'api/v1/user-groups';
export const FAVICON_REQUEST = '/api/v1/favicon/';
export const IMAGES_REQUEST = '/api/v1/images/';

export const WISHLIST_URL = '/api/v1/wishlist';

export const REWARDS = URL + '/api/v1/rewardprogram';
export const REWARDS_URL = '/api/v1/rewardprogram';

export const ALL_USER_GROUP = '/api/v1/user-groups/admin/';
export const GET_CONTACT = '/api/v1/user/:userId/contact';
export const ASSIGN_USER_GROUP = '/api/v1/user/groups/'
export const CUSTOM_ADVANCE_URL = '/api/v1/advance/custom'
export const CREATE_ARTWORK_URL = '/api/v1/artworkdata/custom'