import React, { useContext, useState } from 'react'
import { FulfillmentMethodType } from '../../../models/FulfillmentMethod'
import { FulfillmentForm } from './FulfillmentActiveContent'
import FulfillmentQuoteContent from './FulfillmentQuoteContent'
import AddressSelector from '../AddressSelector'
import { useAppSelector } from '../../../redux/hooks'
import {
  selectFulfillments,
  selectParams,
  selectSettings,
} from '../../../redux/selectors/checkoutSelectors'
import { getValidFulfillments } from '../../../helpers/checkout'
import { AuthContext } from '../../../common/Auth'

interface PickupMethodContactProps {
  index: number
  onSubmit: (data: FulfillmentForm) => Promise<void>
  error?: string
  submitLoading: boolean
}

export default function PickupMethodContact({
  index,
  onSubmit,
  submitLoading,
  error,
}: PickupMethodContactProps) {
  const { user } = useContext(AuthContext)
  const settings = useAppSelector(selectSettings)

  return (
    <>
      <AddressSelector
        label="Pickup"
        formPrefix={`fulfillments.${index}`}
        contactOnly
        requirePhone={settings.isFulfillmentPhoneRequired}
        requireCompany={settings.isFulfillmentCompanyRequired}
        companyRename={
          settings.isFulfillmentCompanyRenameEnabled
            ? settings.fulfillmentCompanyRenameText
            : undefined
        }
        lockName={settings.isFulfillmentNameLocked}
        lockEmail={settings.isFulfillmentEmailLocked}
      >
        <FulfillmentQuoteContent
          index={index}
          label="Pickup"
          type={FulfillmentMethodType.PICKUP}
          submitLoading={submitLoading}
          error={error}
          onSubmit={onSubmit}
        />
      </AddressSelector>
    </>
  )
}
