import React from 'react'
import {
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Radio,
  Stack,
  styled,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import { Warning } from '@mui/icons-material'
import AnimateHeight from '../../common/AnimateHeight'

interface OptionCardProps {
  iconComponent: React.ReactNode
  label: string
  secondaryLabel?: React.ReactNode
  selected: boolean
  onClick: () => void
  disabled?: boolean
  tooltip?: string
  warningText?: string
  actions?: React.ReactNode
  children?: React.ReactNode
}

export const OptionCardActionArea = styled(CardActionArea)({
  borderRadius: 0,
})

interface OverlayProps {
  disabled: boolean
  selected: boolean
}

const StyledCard = styled(Card)<OverlayProps>(
  ({ disabled, selected, theme }) => ({
    borderColor: selected ? theme.palette.primary.main + '77' : undefined,
    backgroundColor: disabled ? grey[200] : undefined,
    opacity: disabled ? 0.5 : 1,
  }),
)

export default function OptionCard({
  iconComponent,
  label,
  secondaryLabel,
  selected,
  onClick,
  disabled = false,
  tooltip,
  warningText,
  actions,
  children,
}: OptionCardProps) {
  return (
    <AnimateHeight>
      <Tooltip title={tooltip} followCursor>
        <StyledCard disabled={disabled} selected={selected}>
          <ContentWrapper action={!disabled && !selected} onClick={onClick}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              m={2}
            >
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                {iconComponent}
                <Typography
                  variant="body1"
                  fontSize={children ? '1.15rem' : undefined}
                >
                  {label}
                </Typography>
              </Stack>
              <Stack direction={'row'} spacing={2} alignItems={'center'}>
                <Stack direction={'row'} spacing={2} alignItems={'center'}>
                  {!disabled && warningText && (
                    <Tooltip title={warningText}>
                      <Warning color={'warning'} />
                    </Tooltip>
                  )}
                  {secondaryLabel && (
                    <Typography variant="body1">{secondaryLabel}</Typography>
                  )}
                  {!disabled && selected ? actions : null}
                  {!disabled && <Radio checked={selected} />}
                </Stack>
              </Stack>
            </Stack>
          </ContentWrapper>
          {selected && children ? <Divider /> : undefined}
          {selected && children}
        </StyledCard>
      </Tooltip>
    </AnimateHeight>
  )
}

function ContentWrapper({
  action,
  onClick,
  children,
}: {
  action: boolean
  onClick: () => void
  children: React.ReactNode
}) {
  if (action) {
    return (
      <OptionCardActionArea disableTouchRipple onClick={onClick}>
        {children}
      </OptionCardActionArea>
    )
  } else {
    return <>{children}</>
  }
}
