import { Button, Card, CardContent, Stack, Typography } from '@mui/material'
import React, { forwardRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../redux/hooks'
import {
  selectActiveStep,
  selectLockChanges,
  selectParams,
  selectSettings,
  selectStatus,
} from '../../../redux/selectors/checkoutSelectors'
import { CheckoutStatus, CheckoutStep } from '../../../models/CheckoutSession'
import { editStep } from '../../../redux/reducers/checkoutReducer'
import FulfillmentActiveContent from './FulfillmentActiveContent'
import FulfillmentPassiveContent from './FulfillmentPassiveContent'

export default forwardRef<HTMLDivElement>(function FulfillmentCard(_, ref) {
  const dispatch = useAppDispatch()
  const params = useAppSelector(selectParams)
  const activeStep = useAppSelector((state) => selectActiveStep(state, params))
  const status = useAppSelector((state) => selectStatus(state, params))
  const lockChanges = useAppSelector((state) =>
    selectLockChanges(state, params),
  )
  const settings = useAppSelector(selectSettings)

  const handleEditStep = () => {
    dispatch(editStep(CheckoutStep.FULFILLMENT))
  }

  return (
    <div ref={ref}>
      <Card variant="outlined">
        <CardContent>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant="h5" gutterBottom>
              {settings.fulfillmentRenameEnabled &&
              settings.fulfillmentRenameText
                ? settings.fulfillmentRenameText
                : 'Delivery'}
            </Typography>
            {!lockChanges &&
              status === CheckoutStatus.PENDING_PAYMENT &&
              activeStep !== CheckoutStep.FULFILLMENT && (
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleEditStep}
                >
                  Edit
                </Button>
              )}
          </Stack>
          {activeStep === CheckoutStep.FULFILLMENT && (
            <FulfillmentActiveContent />
          )}
          {activeStep !== CheckoutStep.FULFILLMENT && (
            <FulfillmentPassiveContent />
          )}
        </CardContent>
      </Card>
    </div>
  )
})
